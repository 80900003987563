import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.2ality.com/"
          }}>{`http://www.2ality.com/`}</MDXTag>{` - Tons and tons of useful information about next version of javascript (ES6) and more`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://addyosmani.com/blog/"
          }}>{`https://addyosmani.com/blog/`}</MDXTag>{` - Advanced level articles about javascript architecture and web performance`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://csswizardry.com/#section:articles"
          }}>{`https://csswizardry.com/#section:articles`}</MDXTag>{` - CSS may end up being a nightmare if you don't take care to structure it well. You'll find everything you need to know about CSS in that blog`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://css-tricks.com"
          }}>{`https://css-tricks.com`}</MDXTag>{` - Blog with tons of posts about web development`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://geek-and-poke.com/"
          }}>{`http://geek-and-poke.com/`}</MDXTag>{` - Smart humor about software development`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.stevesouders.com/blog/"
          }}>{`https://www.stevesouders.com/blog/`}</MDXTag>{` - Web performance the right way`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://javascriptweekly.com"
          }}>{`https://javascriptweekly.com`}</MDXTag>{` - Be part of javascript community, get updates weekly`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://blog.jetbrains.com/webstorm/"
          }}>{`https://blog.jetbrains.com/webstorm/`}</MDXTag>{` - Detailed feature overview of the best IDE for javascript`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://humanwhocodes.com"
          }}>{`https://humanwhocodes.com`}</MDXTag>{` - Unique articles about web development, work relationship and many more`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://nodeweekly.com"
          }}>{`https://nodeweekly.com`}</MDXTag>{` - Weekly news from nodejs world`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://yarnpkg.com/blog/"
          }}>{`https://yarnpkg.com/blog/`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://blog.npmjs.org"
          }}>{`https://blog.npmjs.org`}</MDXTag>{` - package manager updates`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://perfectionkills.com/"
          }}>{`http://perfectionkills.com/`}</MDXTag>{` - Always high level, ultimate quality posts about web development`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://dmitrysoshnikov.com/"
          }}>{`http://dmitrysoshnikov.com/`}</MDXTag>{` - Learn how javascript is working on the core level. Ecmascript specifications translated to the human language`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://serversforhackers.com"
          }}>{`https://serversforhackers.com`}</MDXTag>{` - Great tutorials and articles about server configuration`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://goo.gl/7zaoGm"
          }}>{`https://goo.gl/7zaoGm`}</MDXTag>{` - Clean Code book, learn how to write a professional code`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://goo.gl/dRXk1T"
          }}>{`https://goo.gl/dRXk1T`}</MDXTag>{` - Clean Coder book, learn how to be a professional developer. When and how to say No and feel good about it. How to say Yes and what exactly it means. Any many more`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Recommended",
  "date": "2015-01-19T19:36:23.000Z",
  "overview": "Recommended articles, blogs and books for professional web developers",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "isInTopBar": true
};
    